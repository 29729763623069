/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getPlatformOrganisationsSites } from '../fn/platform/get-platform-organisations-sites';
import { GetPlatformOrganisationsSites$Params } from '../fn/platform/get-platform-organisations-sites';
import { getPlatformOrganizations } from '../fn/platform/get-platform-organizations';
import { GetPlatformOrganizations$Params } from '../fn/platform/get-platform-organizations';
import { getPlatformOrganizationsSitesBySiteKey } from '../fn/platform/get-platform-organizations-sites-by-site-key';
import { GetPlatformOrganizationsSitesBySiteKey$Params } from '../fn/platform/get-platform-organizations-sites-by-site-key';
import { OrganizationKeysAndNames } from '../models/organization-keys-and-names';
import { OrganizationSite } from '../models/organization-site';
import { OrgOrganizationSite } from '../models/org-organization-site';


/**
 * Endpoints that can be accesssed by every logged in user
 */
@Injectable({ providedIn: 'root' })
export class PlatformService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPlatformOrganizations()` */
  static readonly GetPlatformOrganizationsPath = '/platform/organizations';

  /**
   * Get all organization keys.
   *
   * Get a list of all organization keys.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlatformOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlatformOrganizations$Response(params?: GetPlatformOrganizations$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationKeysAndNames>> {
    return getPlatformOrganizations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all organization keys.
   *
   * Get a list of all organization keys.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlatformOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlatformOrganizations(params?: GetPlatformOrganizations$Params, context?: HttpContext): Observable<OrganizationKeysAndNames> {
    return this.getPlatformOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationKeysAndNames>): OrganizationKeysAndNames => r.body)
    );
  }

  /** Path part for operation `getPlatformOrganisationsSites()` */
  static readonly GetPlatformOrganisationsSitesPath = '/platform/organization-sites';

  /**
   * Get all organization sites.
   *
   * Get list of all organizations sites including their GLN and metadata.
   * Does not return archived organization sites
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlatformOrganisationsSites()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlatformOrganisationsSites$Response(params?: GetPlatformOrganisationsSites$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationSite>>> {
    return getPlatformOrganisationsSites(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all organization sites.
   *
   * Get list of all organizations sites including their GLN and metadata.
   * Does not return archived organization sites
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlatformOrganisationsSites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlatformOrganisationsSites(params?: GetPlatformOrganisationsSites$Params, context?: HttpContext): Observable<Array<OrganizationSite>> {
    return this.getPlatformOrganisationsSites$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationSite>>): Array<OrganizationSite> => r.body)
    );
  }

  /** Path part for operation `getPlatformOrganizationsSitesBySiteKey()` */
  static readonly GetPlatformOrganizationsSitesBySiteKeyPath = '/platform/organization-sites/{organizationSiteKey}';

  /**
   * Get a specific organization site.
   *
   * Get a specific organization site by organization site key.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlatformOrganizationsSitesBySiteKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlatformOrganizationsSitesBySiteKey$Response(params: GetPlatformOrganizationsSitesBySiteKey$Params, context?: HttpContext): Observable<StrictHttpResponse<OrgOrganizationSite>> {
    return getPlatformOrganizationsSitesBySiteKey(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a specific organization site.
   *
   * Get a specific organization site by organization site key.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlatformOrganizationsSitesBySiteKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlatformOrganizationsSitesBySiteKey(params: GetPlatformOrganizationsSitesBySiteKey$Params, context?: HttpContext): Observable<OrgOrganizationSite> {
    return this.getPlatformOrganizationsSitesBySiteKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrgOrganizationSite>): OrgOrganizationSite => r.body)
    );
  }

}
